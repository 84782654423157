import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import Footer from '../footer/footer';
import { useEffect } from 'react';
import HomeAboutImg from '../../assets/img/water-expo-blue.png';

export default function MainAbout() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div>
        <InnerNavbar/>
        <div className='about-banner container-fluid'>
          <div className='banner-bg'>
            <h1>About Us</h1>
          </div>
        </div>
        <div className='main-about container-fluid'>
          <div className='custom-container'>
            <div className='row'>
              <div className='col-md-7 col-12 m-auto'>
                <div className='about-content'>
                  <h6>About</h6>
                  <h4>Waters Kerala</h4>
                  <p>
                  Waters Kerala, water treatment plant entrepreneur registered society is an association based on Kerala aiming to bring water treatment industry into one roof.Established in the year 2017 with group of vibrant entrepreneurs from all over Kerala. 
                  </p>
                </div>
              </div>
              <div className='col-md-5 col-12'>
                <div className='about-img'>
                <img src={HomeAboutImg}/>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4 col-12'>
                <div className='mission-box-content'>
                  <h6>Mission and Vision</h6>
                  <p>The primary purpose and long-term goals to settle the issues of members and the well being of them and bring unity in the industry and develop skills of our members in technology and personal growth.We highlights our social and economical commitments, organization aims to achieve and plans to make a difference in our field or community.</p>
                </div>
              </div>
              <div className='col-md-4 col-12'>
                <div className='mission-box-content'>
                  <h6>History</h6>
                  <p>First generation members from calicut, alappuzha formulated the organisation in the year 2017 and last year we formed district commitees in all districts that shaped the organization into power team in water treatment industry in wholesale, retail and integrators segment.</p>
                </div>
              </div>
              <div className='col-md-4 col-12'>
                <div className='mission-box-content'>
                  <h6>Objectives and Goals</h6>
                  <p>We aim to develop a group of entrepreneur who can deliver best quality products to thier customers in a ethical way by best practice of sales and marketing by guiding them to procure from certified manifactures and thier products by educating them with direct trainings from vendors. Eliminating nonstandard, unethikal products from the market and Quality Water guaranteed with Waters Kerala Trust Pass.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
    </div>
  )
}
