import React, { useState } from "react";
import InnerNavbar from "../inner-navbar/inner-navbar";
import Footer from "../footer/footer";
import { useEffect } from "react";
import ECMemberOne from "../../assets/img/m1.jpeg";
import ECMemberTwo from "../../assets/img/m2.jpeg";
import ECMemberThree from "../../assets/img/m3.jpeg";
import ECMemberFour from "../../assets/img/m4.jpeg";
import ECMemberFive from "../../assets/img/m5.jpeg";
import ECMemberSix from "../../assets/img/m6.jpeg";
import ECMemberSeven from "../../assets/img/m7.jpeg";
import ECMemberEight from "../../assets/img/m8.jpeg";
import ECMemberNine from "../../assets/img/m9.jpeg";
import ECMemberTen from "../../assets/img/m10.jpeg";
import ECMemberEleven from "../../assets/img/m11.jpg";
import ECMemberTwelve from "../../assets/img/m12.jpg";
import ECMemberFourteen from "../../assets/img/m14.jpg";
import ECMemberFifteen from "../../assets/img/m15.jpg";
import ECMemberSixteen from "../../assets/img/m16.jpg";
import ECMemberSeventeen from "../../assets/img/m17.jpg";

export default function MembersMain() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [members, setMembers] = useState([]);

  useEffect(() => {
    // Fetch the JSON file from the public folder
    fetch('/members.json') // Adjust path if necessary
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setMembers(data))
      .catch(error => console.log(error));
  }, []);

  return (
    <div>
      <InnerNavbar />
      <div className="member-banner container-fluid">
        <div className="banner-bg">
          <h1>Members</h1>
        </div>
      </div>
      <div className="members-main container-fluid">
        <div className="custom-container">
          <h4>Executive Committee</h4>
          <div className="committee-row">
            {
              members.map((d, i) => {
                return(
                  <div key={i} className="member-box">
                    <img src={`/members/${d.img}`} />
                    <h6>{d.name}</h6>
                    <p>{d.designation}</p>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
