import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import GallImgOne from '../../assets/img/gal1.jpeg';
import GallImgTwo from '../../assets/img/gal2.jpeg';
import GallImgThree from '../../assets/img/gal3.jpeg';
import { Link } from 'react-router-dom';
import Footer from '../footer/footer';
import { useEffect } from 'react';

export default function GalleryMain() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return (
    <div>
        <InnerNavbar/>
        <div className='about-banner container-fluid'>
          <div className='banner-bg'>
            <h1>Gallery</h1>
          </div>
        </div>
        <div className='gallery-main container-fluid'>
            <div className='custom-container'>
                <div className='row'>
                    <div className='col-md-4 col-12'>
                        <div className='gallery-album-box'>
                            <Link to="/gallerydetail">
                                <div className='inner-box'>
                                    <img src={GallImgOne}/>
                                    <h4>1st Meeting</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div className='gallery-album-box'>
                            <Link to="/gallerydetailtwo">
                                <div className='inner-box'>
                                    <img src={GallImgTwo}/>
                                    <h4>2nd Meeting</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='col-md-4 col-12'>
                        <div className='gallery-album-box'>
                            <Link to="/gallerydetailthree">
                                <div className='inner-box'>
                                    <img src={GallImgThree}/>
                                    <h4>Training program at Calicut</h4>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
