import React from 'react';
import { Link } from 'react-router-dom';
import HomeAboutImg from '../../assets/img/water-expo-blue.png';


export default function HomeAbout() {
  return (
    <div className='home-about container-fluid'>
        <div className='custom-container'>
            <div className='row'>
                <div className='col-md-7 col-12 m-auto'>
                    <div className='about-content'>
                        <h4>About Us</h4>
                        <p>
                        Waters Kerala, water treatment plant entrepreneur registered society is an association based on Kerala aiming to bring water treatment industry into one roof.Established in the year 2017 with group of vibrant entrepreneurs from all over Kerala. 
                        </p>
                        <Link className='read-more-btn' to="/about">About Us <span class="material-symbols-outlined">trending_flat</span></Link>
                    </div>
                </div>
                <div className='col-md-5 col-12'>
                    <div className='about-img'>
                        <img src={HomeAboutImg}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
