import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home/home";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter, Routes, Route, Link } from "react-router-dom";
import MainAbout from "./components/about/about";
import MainEvents from "./components/events/events";
import MainEventsDetails from "./components/events-detail/events-detail";
import MembersMain from "./components/members/members";
import GalleryMain from "./components/gallery/gallery";
import GalleryDetailMain from "./components/gallery-detail/gallery-detail";
import ContactMain from "./components/contact/contact";
import MemberRegistation from "./components/member-registration/member-registration";
import EventRegister from "./components/event-register/event-register";
import ScrollToTop from "react-scroll-to-top";
import GuestRegistation from "./components/guest-registration/guest-registration";
import MemberSuccess from "./components/member-reg-success/member-success";
import GuestSuccess from "./components/guest-registration-success/guest-success";
import StallRegistration from "./components/event-register/StallRegister";
import StallSuccess from "./components/stallsuccess/Stallsuccess";
import StallLayout from "./components/stall-layout/stall";
import GalleryDetailMainTwo from "./components/gallery-detail-two/gallery-detail-two";
import GalleryDetailMainThree from "./components/gallery-detail-three/gallery-detail-three";
import Stalltest from "./components/stall-layout/stalltest";
// import Test from "./components/test";

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<MainAbout />} />
          <Route exact path="/events" element={<MainEvents />} />
          <Route exact path="/eventsdetail" element={<MainEventsDetails />} />
          <Route exact path="/members" element={<MembersMain />} />
          <Route exact path="/gallery" element={<GalleryMain />} />
          <Route exact path="/gallerydetail" element={<GalleryDetailMain />} />
          <Route exact path="/contact" element={<ContactMain />} />
          <Route
            exact
            path="/memberregistration"
            element={<MemberRegistation />}
          />
          <Route exact path="/eventregister" element={<EventRegister />} />
          <Route exact path="/guestregister" element={<GuestRegistation />} />
          <Route exact path="/membersuccess" element={<MemberSuccess />} />
          <Route exact path="/guestsuccess" element={<GuestSuccess />} />
          <Route exact path="/stallsuccess" element={<StallSuccess />} />
          <Route exact path="/stall" element={<StallLayout />}></Route>
          <Route
            exact
            path="/gallerydetailtwo"
            element={<GalleryDetailMainTwo />}
          />
          <Route
            exact
            path="/gallerydetailthree"
            element={<GalleryDetailMainThree />}
          />
          <Route exact path="/stalltest" element={<Stalltest />} />
          {/* <Route exact path="/test" element={<Test />} /> */}
        </Routes>
        <ScrollToTop smooth />
      </HashRouter>
    </>
  );
}

export default App;
