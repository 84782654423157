import React from "react";
import Footer from "../footer/footer";
import InnerNavbar from "../inner-navbar/inner-navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-dropdown-select";
import StallRegister from "./StallRegister";
import AlreadymemberRegister from "./AlreadymemberRegister";

export default function EventRegister() {
  const options = [
    { value: "A", label: "A" },
    { value: "B", label: "B" },
    { value: "C", label: "C" },
    { value: "D", label: "D" },
  ];

  const [formValues, setFormValues] = useState([{ name: "", email: "" }]);

  let handleChange = (i, e) => {
    let newFormValues = [...formValues];

    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValues, { name: "", email: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    alert(JSON.stringify(formValues));
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <InnerNavbar />
      <div className="about-banner container-fluid">
        <div className="banner-bg">
          <h1>Register</h1>
        </div>
      </div>
      <div className="event-register-main container-fluid">
        <div className="custom-container">
          <div className="row">
            <div className="col-md-5 col-12">
              <div className="member-box">
                <h4>Stall Registration</h4>
                <h6>Reserve Your Spot Now!</h6>
                <StallRegister />
              </div>
            </div>
            <div className="col-md-2 col-12 m-auto">
              <div className="or-div">
                <p>OR</p>
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="guest-register-box">
                <h4>Visitor Registration</h4>
                <h6>Join Us at Water Expo</h6>
                {/* <Form className="row">
                  <div className="col-12">
                    <label>Member ID</label>
                    <Form.Control type="text" placeholder="Company name" />
                  </div>
                  <div className="col-12">
                    <label>Mobile Number</label>
                    <Form.Control type="text" placeholder="Mobile Number" />
                  </div>
                  <div className="col-12">
                    <Button className="read-more-btn">Register</Button>
                  </div>
                </Form> */}
                <AlreadymemberRegister />
                <div className="member-reg-box">
                  <Link to="/memberregistration">
                    Not a Member? Register Now
                  </Link>
                  <h5>OR</h5>
                  <Link to="/guestregister">Register as Guest</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
