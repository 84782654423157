import React from "react";
import Footer from "../footer/footer";
import InnerNavbar from "../inner-navbar/inner-navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { useEffect } from "react";
import GreenTick from "../../assets/img/tick.png";
import { Link } from "react-router-dom";

export default function StallSuccess() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <div className="success-main container-fluid">
        <div className="custom-container">
          <div className="content-box">
            <img src={GreenTick} />
            <h4>Thank You</h4>
            <h5>
              Your stall registration is in progress <br></br> check your email
            </h5>
            <Link to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
