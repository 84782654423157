import React, { useState, useEffect } from "react";
import InnerNavbar from "../inner-navbar/inner-navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Footer from "../footer/footer";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { BASE_URL } from "../../constants/constants";
import { Spinner } from "react-bootstrap";
import { Link, json, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";

export default function StallRegistration() {
  const [memberId, setMemberId] = useState(null);
  const [otpId, setOtpId] = useState(null);
  const [otp, setOtp] = useState("");
  const [memberIDDATA, setMemberIDDATA] = useState("");
  const [otpTimer, setOtpTimer] = useState(180); // 3 minutes in seconds
  const [phone, setPhone] = useState("");
  const [tempdoc, setTempdoc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [visloading, setvisloading] = useState(false);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Fetch unregistered stall names
    fetch(BASE_URL + "list_stall_names")
      .then((response) => response.json())
      .then((data) => {
        const newOptions = data.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setOptions(newOptions);
        setIsLoadingOptions(false);
      })
      .catch((error) => {
        setIsLoadingOptions(false);
        console.error("Error fetching stall names:", error);
      });
  }, []);

  const [selectedValues, setSelectedValues] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValues(selectedList);
    // Your onSelect logic here
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValues(selectedList);
    // Your onRemove logic here
  };

  console.log(selectedValues);

  const navigate = useNavigate();

  const stalltypes = [
    // List of Kerala districts
    // You can replace this with your actual district data
    { value: "1", label: "Green" },
    { value: "2", label: "Red" },
    { value: "3", label: "Yellow" },
    { value: "4", label: "Blue" },
    // ... Add more districts
  ];

  const businessOptions = [
    { value: "1", label: "Wholesale" },
    { value: "2", label: "Retail" },
    { value: "3", label: "Trader" },
    { value: "4", label: "Integrator" },
  ];

  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // Stop the timer when it reaches 0
    if (otpTimer === 0) {
      clearInterval(interval);
    }
  }, [otpTimer]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData(event.target);

    formData.set("primary_phone", phone);
    formData.set("stalls", JSON.stringify(selectedValues));

    const selectedRadio = formData.get("doc_type");
    formData.delete("doc_type");

    const selectedBusinessDistrict = formData.get(
      "primary_business_districts_id"
    );
    const matchingBusinessDistrict = stalltypes.find(
      (option) => option.value === selectedBusinessDistrict
    );
    if (matchingBusinessDistrict) {
      formData.set(
        "primary_business_districts_id",
        matchingBusinessDistrict.value
      );
    }

    const selectedPersonalDistrict = formData.get(
      "primary_personal_districts_id"
    );
    const matchingPersonalDistrict = stalltypes.find(
      (option) => option.value === selectedPersonalDistrict
    );
    if (matchingPersonalDistrict) {
      formData.set(
        "primary_personal_districts_id",
        matchingPersonalDistrict.value
      );
    }

    try {
      const response = await fetch(BASE_URL + "create_stall_register", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        setIsLoading(false);
        setMemberId(data.stall_registration_id);
        setMemberIDDATA(data.stall_registration_id);
        setOtpId(data.otp_id);
        setTempdoc(data.doc_id);
        startOtpTimer();
      } else {
        setIsLoading(false);

        alert("request failed, please try again later");
        console.error("Error:", data.message);
      }
    } catch (error) {
      setIsLoading(false);
      alert("request failed, please try again later");
      console.error("Error:", error);
    }
  };
  const handleOtpVerification = async () => {
    setvisloading(true);
    try {
      const requestBody = {
        id: otpId,
        otpdata: otp,
        stall_registration_id: memberId,
      };

      const response = await fetch(BASE_URL + "verifyotp_stall_registration", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setvisloading(false);
        // Forward to another page upon successful verification
        // window.location.href = "/success-page";
        navigate("/stallsuccess");
        // alert(
        //   "Stall registration is successfull!\nWe have send you a confirmation mail!"
        // );
      } else {
        setvisloading(false);
        // Handle OTP verification error
        alert("request failed, please try again later");
        console.error("Error verifying OTP");
      }
    } catch (error) {
      setvisloading(false);
      alert("request failed, please try again later");
      console.error("Error:", error);
    }
  };

  let interval; // Declare the interval variable outside the function
  const startOtpTimer = () => {
    interval = setInterval(() => {
      setOtpTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval); // Stop the interval when timer reaches 0
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1500);
  };

  const handleResendOtp = async () => {
    try {
      const formData = new FormData();
      formData.append("stall_id", memberIDDATA);
      formData.append("primary_phone", phone);

      const response = await fetch(BASE_URL + "stall_resend_otp", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        setOtpId(data.otp_id);
        setOtpTimer(180);
        // Handle success, maybe show a message to the user
        startOtpTimer(); // Restart the OTP timer
      } else {
        alert("request failed, please try again later");
        // Handle resend OTP error
        console.error("Error resending OTP");
      }
    } catch (error) {
      alert("request failed, please try again later");
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <Form onSubmit={handleFormSubmit}>
        {/* Full Name */}
        <Form.Group controlId="company_name">
          <Form.Label>
            Company Name <span className="man-field">*</span>
          </Form.Label>
          <Form.Control type="text" name="company_name" required />
        </Form.Group>

        {/* Primary Phone */}
        <Form.Group controlId="primary_phone">
          <Form.Label>
            Mobile Number <span className="man-field">*</span>
          </Form.Label>
          <PhoneInput
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
            required
          />
          {/* <Form.Control type="tel" name="primary_phone" required /> */}
        </Form.Group>

        {/* Industry */}
        <Form.Group controlId="industry">
          <Form.Label>
            Industry <span className="man-field">*</span>
          </Form.Label>
          <Form.Control type="text" name="industry" required />
        </Form.Group>

        {/* Primary Business Name */}
        <Form.Group controlId="contact_person_name">
          <Form.Label>
            Contact Person Name <span className="man-field">*</span>
          </Form.Label>
          <Form.Control type="text" name="contact_person_name" required />
        </Form.Group>

        {/* Primary Email */}
        <Form.Group controlId="primary_email">
          <Form.Label>
            Email <span className="man-field">*</span>
          </Form.Label>
          <Form.Control type="email" name="primary_email" required />
        </Form.Group>

        <Form.Group controlId="stall_types_id ">
          <Form.Label>
            Stall Type Required <span className="man-field">*</span>
          </Form.Label>
          <select name="stall_types_id" required>
            {stalltypes.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </Form.Group>
        <Form.Label>
          Stall No. Required <span className="man-field">*</span>
        </Form.Label>
        {isLoadingOptions ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Multiselect
            emptyRecordMsg="No Stall Available"
            options={options}
            selectedValues={selectedValues}
            onSelect={onSelect}
            onRemove={onRemove}
            displayValue="name"
            loading={isLoadingOptions}
          />
        )}

        <div>
          <Link to={"/stall"}>View all stall layout</Link>
        </div>

        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Button className="read-more-btn" type="submit">
            Submit
          </Button>
        )}
      </Form>

      {otpId && memberId && (
        <div className="form-otp-box">
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />

          {visloading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Button className="read-more-btn" onClick={handleOtpVerification}>
              Verify OTP
            </Button>
          )}

          <p>
            {otpTimer === 0 ? (
              <span onClick={handleResendOtp}>Click here to resend</span>
            ) : (
              `Resend OTP in ${otpTimer} seconds`
            )}
          </p>
        </div>
      )}
    </div>
  );
}
