import React from "react";
import InnerNavbar from "../inner-navbar/inner-navbar";
import EventImgOne from "../../assets/img/event1.jpg";
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import Footer from "../footer/footer";
import { useEffect } from "react";

export default function MainEvents() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <InnerNavbar />
      <div className="events-banner container-fluid">
        <div className="banner-bg">
          <h1>Events</h1>
        </div>
      </div>
      <div className="main-events container-fluid">
        <div className="custom-container">
          <div className="event-box">
            <div className="row">
              <div className="col-md-7 col-12">
                <div className="event-img">
                  <img src={EventImgOne} />
                </div>
              </div>
              <div className="col-md-5 col-12 m-auto">
                <div className="event-content">
                  <h6>27-09-2024</h6>
                  <h4>Water Expo</h4>
                  <p>
                    Waters Kerala Water Expo is an unparalleled gathering of
                    industry leaders, experts, and enthusiasts, aimed at
                    fostering innovation, collaboration, and growth. With a
                    diverse range of activities and an array of prominent
                    speakers, it promises to be an extraordinary event that will
                    leave a lasting impact on all attendees.
                  </p>
                  <Link to="/eventsdetail">
                    View More <HiOutlineArrowNarrowRight />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
