import React from "react";
import Footer from "../footer/footer";
import InnerNavbar from "../inner-navbar/inner-navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { useEffect, useState } from "react";
import { BASE_URL, MAIL_URL } from "../../constants/constants";
import { Spinner } from "react-bootstrap";

export default function ContactMain() {
  const [formData, setFormData] = useState({
    email_data: "",
    name_data: "",
    phone_data: "",
    message_data: "",
  });

  const [isloading, setisloading] = useState(false);
  // const [succ, setsucc] = useState(false);
  // const [fail, setfail] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setisloading(true);
    try {
      const response = await fetch(BASE_URL + "contactus", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // setsucc(true);
        // setfail(false);
        setisloading(false);
        setFormData({
          email_data: "",
          name_data: "",
          phone_data: "",
          message_data: "",
        });

        alert("Successful... we will contact you shortly!");
        // Handle success, e.g., show a success message or redirect
      } else {
        // setfail(true);
        // setsucc(false);
        setisloading(false);

        alert("Failed... please try again later!");
        // Handle error, e.g., show an error message
      }
    } catch (error) {
      setisloading(false);

      alert("Successful... please try again later!");
      // Handle error, e.g., show an error message
    }
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  return (
    <div>
      <InnerNavbar />
      <div className="about-banner container-fluid">
        <div className="banner-bg">
          <h1>Contact Us</h1>
        </div>
      </div>
      <div className="contact-main container-fluid">
        <div className="custom-container">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="address-box">
                <div className="row">
                  <div className="col-12">
                    <div className="inner-box">
                      <h4>Phone</h4>
                      <a href="tel:+916235035050">+91 62350 35050</a>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="inner-box">
                      <h4>Mail</h4>
                      <a href="mailto:waterskerala@gmail.com">
                        waterskerala@gmail.com
                      </a>
                      <a href="mailto:expo.waterskerala@gmail.com">
                        expo.waterskerala@gmail.com
                      </a>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="inner-box">
                      <h4>Address</h4>
                      <p>
                        Water Treatment Plant Entrepreneurs Registered Society
                      </p>
                      <p>Reg : ALP/TC/6420</p>
                      <p>Opp. Telephone Bhavan</p>
                      <p>Cherthala</p>
                      <p>Alappuzha Kerala</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="form-box">
                <h4>Enquire Now</h4>
                <Form className="row" onSubmit={handleSubmit}>
                  <div className="col-md-6 col-12">
                    <Form.Control
                      type="text"
                      placeholder="Your name"
                      name="name_data"
                      value={formData.name_data}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email_data"
                      value={formData.email_data}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12 col-12">
                    <Form.Control
                      type="tel"
                      placeholder="Phone"
                      name="phone_data"
                      value={formData.phone_data}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12">
                    <Form.Control
                      as="textarea"
                      placeholder="Message"
                      rows="4"
                      cols="50"
                      name="message_data"
                      value={formData.message_data}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="col-12">
                    {succ ? (
                      <span>Success</span>
                    ) : fail ? (
                      <span>failed</span>
                    ) : (
                      ""
                    )}
                  </div> */}
                  <div className="col-12">
                    {isloading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        className="mt-2"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      <Button type="submit" className="read-more-btn mt-3">
                        Submit
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="location-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d983.2527457868513!2d76.34014394826963!3d9.680101920087766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b087c37c2acaae9%3A0x40540b01335b004e!2sWaters%20Kerala%20Association!5e0!3m2!1sen!2sin!4v1692252640707!5m2!1sen!2sin"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
