import React from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';
import ModalImage from "react-modal-image";
import GallImgOne from '../../assets/img/gal1.jpeg';
import Footer from '../footer/footer';
import { useEffect } from 'react';

const closeLightbox = () => {
  this.state.open = true;
};

export default function GalleryDetailMain() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <div>
      <InnerNavbar/>
      <div className='about-banner container-fluid'>
        <div className='banner-bg'>
          <h1>Gallery</h1>
        </div>
      </div>
      <div className='gallery-detail-main container-fluid'>
        <div className='custom-container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='gallery-detail-box'>
                <ModalImage
                  small={GallImgOne}
                  large={GallImgOne}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
  

    </div>
  ) 
}
