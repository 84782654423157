import React, { useState } from "react";
import InnerNavbar from "../inner-navbar/inner-navbar";
import EventImgOne from "../../assets/img/event1.jpg";
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import Footer from "../footer/footer";
import { useEffect } from "react";
import { BASE_URL } from "../../constants/constants";
import { Spinner } from "react-bootstrap";

export default function StallLayout() {
  const [data, setData] = useState([]);
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setisloading(true);
      try {
        const response = await fetch(BASE_URL + "getstallinfo", {
          headers: {
            Origin: "https://waterskerala.com/", // Replace with your actual origin URL
            "Content-Type": "application/json", // Example content type
          },
        }); // Replace with the actual API endpoint URL
        const jsonData = await response.json();
        setData(jsonData);
        setisloading(false);
      } catch (error) {
        setisloading(false);
        alert(error);
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <InnerNavbar />
      <div className="events-banner container-fluid">
        <div className="banner-bg">
          <h1>Stall Layout</h1>
        </div>
      </div>
      {isloading ? (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <div className="stall-layout-main container-fluid">
          <div className="custom-container">
            <div className="layout-box">
              <div className="stage-area">
                <div className="exit">
                  <h6>Exit</h6>
                </div>
                <div className="stage">
                  <h6>Stage</h6>
                </div>
              </div>
              <div className="audience-area">
                <div className="a-stall-box">
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[0].status
                        ? "stall-box blue-box"
                        : "stall-box blue-box booked"
                    }
                  >
                    <p>
                      {data.length > 0 && data[0].company
                        ? data[0].company
                        : "D3"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[1].status
                        ? "stall-box blue-box"
                        : "stall-box blue-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[1].company
                        ? data[1].company
                        : "D2"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[2].status
                        ? "stall-box blue-box"
                        : "stall-box blue-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[2].company
                        ? data[2].company
                        : "D1"}
                    </p>
                  </div>
                </div>
                <div className="audience-box">
                  <h6>Audience Area</h6>
                </div>
              </div>
              <div className="stall-main-area">
                <div className="row1">
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[3].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[3].company
                        ? data[3].company
                        : "A8"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[4].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[4].company
                        ? data[4].company
                        : "A7"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[5].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[5].company
                        ? data[5].company
                        : "A6"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[6].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[6].company
                        ? data[6].company
                        : "A5"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[7].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[7].company
                        ? data[7].company
                        : "C12"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[35].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[35].company
                        ? data[35].company
                        : "C13"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[8].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[8].company
                        ? data[8].company
                        : "C11"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[34].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[34].company
                        ? data[34].company
                        : "C14"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[36].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[36].company
                        ? data[36].company
                        : "B14"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[37].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[37].company
                        ? data[37].company
                        : "B13"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[38].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[38].company
                        ? data[38].company
                        : "B12"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[39].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[39].company
                        ? data[39].company
                        : "B11"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[9].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[9].company
                        ? data[9].company
                        : "C10"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[33].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[33].company
                        ? data[33].company
                        : "C15"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[40].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[40].company
                        ? data[40].company
                        : "B16"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[41].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[41].company
                        ? data[41].company
                        : "B15"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[36].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[36].company
                        ? data[36].company
                        : "B10"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[43].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[43].company
                        ? data[43].company
                        : "B9"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[10].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[10].company
                        ? data[10].company
                        : "C9"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[32].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[32].company
                        ? data[32].company
                        : "C16"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[11].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[11].company
                        ? data[11].company
                        : "C8"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[31].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[31].company
                        ? data[31].company
                        : "C17"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[44].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[44].company
                        ? data[44].company
                        : "B18"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[45].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[45].company
                        ? data[45].company
                        : "B17"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[46].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[46].company
                        ? data[46].company
                        : "B8"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[47].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[47].company
                        ? data[47].company
                        : "B7"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[12].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[12].company
                        ? data[12].company
                        : "C7"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[30].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[30].company
                        ? data[30].company
                        : "C18"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[48].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[48].company
                        ? data[48].company
                        : "B20"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[49].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[49].company
                        ? data[49].company
                        : "B19"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[50].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[50].company
                        ? data[50].company
                        : "B6"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[51].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[51].company
                        ? data[51].company
                        : "B5"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[13].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[13].company
                        ? data[13].company
                        : "C6"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[29].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[29].company
                        ? data[29].company
                        : "C19"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[14].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[14].company
                        ? data[14].company
                        : "C5"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[28].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[28].company
                        ? data[28].company
                        : "C20"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[52].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[52].company
                        ? data[52].company
                        : "B22"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[53].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[53].company
                        ? data[53].company
                        : "B21"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[54].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[54].company
                        ? data[54].company
                        : "B4"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[55].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[55].company
                        ? data[55].company
                        : "B3"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[15].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[15].company
                        ? data[15].company
                        : "C4"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[27].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[27].company
                        ? data[27].company
                        : "C21"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[56].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[56].company
                        ? data[56].company
                        : "B24"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[57].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[57].company
                        ? data[57].company
                        : "B23"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[58].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[58].company
                        ? data[58].company
                        : "B2"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[59].status
                        ? "stall-box green-box"
                        : "stall-box green-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[59].company
                        ? data[59].company
                        : "B1"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[16].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[16].company
                        ? data[16].company
                        : "C3"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[26].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[26].company
                        ? data[26].company
                        : "C22"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[17].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[17].company
                        ? data[17].company
                        : "C2"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[25].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[25].company
                        ? data[25].company
                        : "C23"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[23].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[23].company
                        ? data[23].company
                        : "A4"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[22].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[22].company
                        ? data[22].company
                        : "A3"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[21].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[21].company
                        ? data[21].company
                        : "A2"}
                    </p>
                  </div>
                  <div
                    className={
                      data.length > 0 && data[20].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[20].company
                        ? data[20].company
                        : "A1"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[18].status
                        ? "stall-box yellow-box"
                        : "stall-box yellow-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[18].company
                        ? data[18].company
                        : "C1"}
                    </p>
                  </div>
                </div>
                <div className="row1">
                  <div
                    className={
                      data.length > 0 && data[24].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[24].company
                        ? data[24].company
                        : "A10"}
                    </p>
                  </div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box entry">
                    <h6>Entry</h6>
                  </div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div className="stall-box"></div>
                  <div
                    className={
                      data.length > 0 && data[19].status
                        ? "stall-box red-box"
                        : "stall-box red-box booked"
                    }
                  >
                    <p>
                      {" "}
                      {data.length > 0 && data[19].company
                        ? data[19].company
                        : "A9"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
