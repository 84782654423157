import React from 'react';
import Footer from '../footer/footer';
import HomeAbout from '../home-about/home-about';
import HomeCommittee from '../home-committee/home-committee';
import HomeEventsSection from '../home-events-section/home-events-section';
import HomeService from '../home-service/home-service';
import HomeSlider from '../home-slider/home-slider';
import { useEffect } from 'react';
import InnerNavbar from '../inner-navbar/inner-navbar';

export default function Home() {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
  return (
    <div>
        <InnerNavbar/>
        <div className='home-slider-main'>
            <HomeSlider/>
        </div>
        <HomeAbout/>
        <HomeEventsSection/>
        <HomeCommittee/>
        <HomeService/>
        <Footer/>
    </div>
  )
}
