import React, { useState, useEffect } from "react";
import InnerNavbar from "../inner-navbar/inner-navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Footer from "../footer/footer";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { BASE_URL } from "../../constants/constants";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function GuestRegistation() {
  const [memberId, setMemberId] = useState(null);
  const [otpId, setOtpId] = useState(null);
  const [otp, setOtp] = useState("");
  const [memberIDDATA, setMemberIDDATA] = useState("");
  const [otpTimer, setOtpTimer] = useState(180); // 3 minutes in seconds
  const [phone, setPhone] = useState("");
  const [tempdoc, setTempdoc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [visloading, setvisloading] = useState(false);

  const navigate = useNavigate();

  const districtOptions = [
    { value: "1", label: "Alappuzha" },
    { value: "2", label: "Ernakulam" },
    { value: "3", label: "Idukki" },
    { value: "4", label: "Kannur" },
    { value: "5", label: "Kasaragod" },
    { value: "6", label: "Kollam" },
    { value: "7", label: "Kottayam" },
    { value: "8", label: "Kozhikode" },
    { value: "9", label: "Malappuram" },
    { value: "10", label: "Palakkad" },
    { value: "11", label: "Pathanamthitta" },
    { value: "12", label: "Thiruvananthapuram" },
    { value: "13", label: "Thrissur" },
    { value: "14", label: "Wayanad" },
    // Add more districts if needed
  ];

  const businessOptions = [
    { value: "1", label: "Wholesale" },
    { value: "2", label: "Retail" },
    { value: "3", label: "Trader" },
    { value: "4", label: "Integrator" },
  ];

  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // Stop the timer when it reaches 0
    if (otpTimer === 0) {
      clearInterval(interval);
    }
  }, [otpTimer]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = new FormData(event.target);

    formData.set("primary_phone", phone);

    // Check which radio button is selected
    const selectedRadio = formData.get("doc_type");
    formData.delete("doc_type"); // Remove the radio button value from the form data

    // const docFile = document.querySelector('input[name="doc_file"]');
    // formData.append("doc_file", docFile.files[0]); // Append the selected file to the FormData

    const selectedBusinessDistrict = formData.get(
      "primary_business_districts_id"
    );
    const matchingBusinessDistrict = districtOptions.find(
      (option) => option.value === selectedBusinessDistrict
    );
    if (matchingBusinessDistrict) {
      formData.set(
        "primary_business_districts_id",
        matchingBusinessDistrict.value
      );
    }

    // Capture selected personal district
    const selectedPersonalDistrict = formData.get(
      "primary_personal_districts_id"
    );
    const matchingPersonalDistrict = districtOptions.find(
      (option) => option.value === selectedPersonalDistrict
    );
    if (matchingPersonalDistrict) {
      formData.set(
        "primary_personal_districts_id",
        matchingPersonalDistrict.value
      );
    }

    try {
      const response = await fetch(BASE_URL + "create_guest_user_register", {
        method: "POST",
        body: formData, // Use the FormData for the POST request
      });

      const data = await response.json();
      if (response.ok) {
        setIsLoading(false);
        setMemberId(data.guest_id);
        setMemberIDDATA(data.guest_id);
        setOtpId(data.otp_id);
        setTempdoc(data.doc_id);
        startOtpTimer();
      } else {
        // Handle error
        alert("request failed, please try again later");
        console.error("Error:", data.message);
      }
    } catch (error) {
      alert("request failed, please try again later");
      console.error("Error:", error);
    }
  };
  const handleOtpVerification = async () => {
    setvisloading(true);
    try {
      const requestBody = {
        id: otpId,
        otpdata: otp,
        temp_guest: memberId,
      };

      const response = await fetch(BASE_URL + "verifyotp_guest", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setvisloading(false);
        // alert(
        //   "Event registration is successfull!\nWe have send you a confirmation mail!"
        // );
        // window.location.href = "/membersuccess";
        navigate("/guestsuccess");
      } else {
        setvisloading(false);
        // Handle OTP verification error
        alert("request failed, please try again later");
        console.error("Error verifying OTP");
      }
    } catch (error) {
      setvisloading(false);
      alert("request failed, please try again later");
      console.error("Error:", error);
    }
  };

  let interval; // Declare the interval variable outside the function
  const startOtpTimer = () => {
    interval = setInterval(() => {
      setOtpTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval); // Stop the interval when timer reaches 0
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1500);
  };

  const handleResendOtp = async () => {
    try {
      const formData = new FormData();
      formData.append("guest_id", memberIDDATA);
      formData.append("primary_phone", phone);

      const response = await fetch(BASE_URL + "guest_registration_resend_otp", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        setOtpId(data.otp_id);
        setOtpTimer(180);
        // Handle success, maybe show a message to the user
        startOtpTimer(); // Restart the OTP timer
      } else {
        // Handle resend OTP error
        alert("request failed, please try again later");
        console.error("Error resending OTP");
      }
    } catch (error) {
      alert("request failed, please try again later");
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <InnerNavbar />
      <div className="about-banner container-fluid">
        <div className="banner-bg">
          <h1>Guest Registration</h1>
        </div>
      </div>
      <div className="member-registration-main container-fluid">
        <div className="custom-container">
          <div className="form-box">
            <Form className="row" onSubmit={handleFormSubmit}>
              <div className="col-md-6">
                {/* Full Name */}
                <Form.Group controlId="full_name">
                  <Form.Label>
                    Full Name <span className="man-field">*</span>
                  </Form.Label>
                  <Form.Control type="text" name="full_name" required />
                </Form.Group>
              </div>

              <div className="col-md-6">
                {/* Primary Email */}
                <Form.Group controlId="primary_email">
                  <Form.Label>
                    Email <span className="man-field">*</span>
                  </Form.Label>
                  <Form.Control type="email" name="primary_email" required />
                </Form.Group>
              </div>

              <div className="col-md-6">
                {/* Primary Phone */}
                <Form.Group controlId="primary_phone">
                  <Form.Label>
                    Phone <span className="man-field">*</span>
                  </Form.Label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={setPhone}
                    required
                  />
                  {/* <Form.Control type="tel" name="primary_phone" required /> */}
                </Form.Group>
              </div>

              <div className="col-md-6">
                {/* Primary Business Name */}
                <Form.Group controlId="primary_business_name">
                  <Form.Label>
                    Business Name <span className="man-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="primary_business_name"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                {/* Industry */}
                <Form.Group controlId="industry">
                  <Form.Label>
                    Industry <span className="man-field">*</span>
                  </Form.Label>
                  <Form.Control type="text" name="industry" required />
                </Form.Group>
              </div>

              <div className="col-md-6">
                {/* Primary Personal Pincode */}
                <Form.Group controlId="primary_personal_pincode">
                  <Form.Label>
                    Personal Pincode <span className="man-field">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="primary_personal_pincode"
                    required
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                {/* Primary Personal District */}
                <Form.Group controlId="primary_personal_districts_id">
                  <Form.Label>
                    Personal District <span className="man-field">*</span>
                  </Form.Label>
                  <select name="primary_personal_districts_id" required>
                    {districtOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </div>

              <div className="col-md-12">
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <Button className="read-more-btn" type="submit">
                    Submit
                  </Button>
                )}
              </div>
            </Form>

            {otpId && memberId && (
              <div className="form-otp-box row">
                <div className="col-md-6 col-12 m-auto">
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />

                  {visloading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      className="spinner"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      className="read-more-btn"
                      onClick={handleOtpVerification}
                    >
                      Verify OTP
                    </Button>
                  )}

                  <p>
                    {otpTimer === 0 ? (
                      <span onClick={handleResendOtp}>
                        Click here to resend
                      </span>
                    ) : (
                      `Resend OTP in ${otpTimer} seconds`
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
