import React, { useState, useEffect } from "react";
import InnerNavbar from "../inner-navbar/inner-navbar";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Footer from "../footer/footer";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { BASE_URL } from "../../constants/constants";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function AlreadymemberRegister() {
  const [memberId, setMemberId] = useState("");
  const [memberIDDATA, setMemberIDDATA] = useState("");
  const [otpId, setOtpId] = useState(null);
  const [otp, setOtp] = useState("");
  const [otpTimer, setOtpTimer] = useState(180); // 3 minutes in seconds
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [visloading, setVisloading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    // Stop the timer when it reaches 0
    if (otpTimer === 0) {
      clearInterval(interval);
    }
  }, [otpTimer]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("member_ID", memberId);
      formData.append("primary_phone", phone);

      const response = await fetch(
        BASE_URL + "create_already_member_event_register",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (response.ok) {
        setIsLoading(false);
        setOtpId(data.otp_id);
        setMemberIDDATA(data.member_id);
        startOtpTimer();
      } else {
        // Handle error
        setIsLoading(false);
        alert("request failed, please try again later");
        console.error("Error:", data.message);
      }
    } catch (error) {
      setIsLoading(false);
      alert("request failed, please try again later");
      console.error("Error:", error);
    }
  };

  const handleOtpVerification = async () => {
    setVisloading(true);
    try {
      const requestBody = {
        id: otpId,
        otpdata: otp,
        member_id: memberIDDATA,
      };

      const response = await fetch(
        BASE_URL + "verifyOtp_already_memebr_registration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setVisloading(false);
        // alert(
        //   "Event registration is successfull!\nWe have send you a confirmation mail!"
        // );
        // window.location.href = "/membersuccess";
        navigate("/membersuccess");
      } else {
        if (data.message == "EXIST") {
          setVisloading(false);
          // Handle OTP verification error
          alert("member already registered!");
        } else {
          setVisloading(false);
          // Handle OTP verification error
          alert("request failed, please try again later");
        }
      }
    } catch (error) {
      setVisloading(false);
      alert("request failed, please try again later");
      console.error("Error:", error);
    }
  };

  let interval; // Declare the interval variable outside the function
  const startOtpTimer = () => {
    interval = setInterval(() => {
      setOtpTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval); // Stop the interval when timer reaches 0
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1500);
  };

  const handleResendOtp = async () => {
    try {
      const formData = new FormData();
      formData.append("member_id", memberIDDATA);
      formData.append("primary_phone", phone);

      const response = await fetch(BASE_URL + "member_already_resend_otp", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        setOtpId(data.otp_id);
        setOtpTimer(180);
        // Handle success, maybe show a message to the user
        startOtpTimer(); // Restart the OTP timer
      } else {
        // Handle resend OTP error
        alert("request failed, please try again later");
        console.error("Error resending OTP");
      }
    } catch (error) {
      alert("request failed, please try again later");
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <Form onSubmit={handleFormSubmit}>
        {/* Member ID */}
        <Form.Group controlId="member_ID">
          <Form.Label>
            Member ID <span className="man-field">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            value={memberId}
            onChange={(e) => setMemberId(e.target.value)}
            required
          />
        </Form.Group>

        {/* Primary Phone */}
        <Form.Group controlId="primary_phone">
          <Form.Label>
            Mobile Number <span className="man-field">*</span>
          </Form.Label>
          <PhoneInput
            placeholder="Mobile Number"
            value={phone}
            onChange={setPhone}
            required
          />
        </Form.Group>

        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <Button className="read-more-btn" type="submit">
            Submit
          </Button>
        )}
      </Form>

      {otpId && (
        <div className="form-otp-box">
          <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />

          {visloading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Button className="read-more-btn" onClick={handleOtpVerification}>
              Verify OTP
            </Button>
          )}

          <p>
            {otpTimer == 0 ? (
              <span onClick={handleResendOtp}>Click here to resend</span>
            ) : (
              `Resend OTP in ${otpTimer} seconds`
            )}
          </p>
        </div>
      )}
    </div>
  );
}
