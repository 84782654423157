import React from "react";
import Logo from "../../assets/img/logo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer container-fluid p-0">
      <div className="custom-container">
        <div className="footer-logo">
          <img src={Logo} />
        </div>
        <div className="footer-nav">
          <Link to="/">Home</Link>
          <Link to="/about">About Us</Link>
          <Link to="/events">Events</Link>
          <Link to="/members">Members</Link>
          <Link to="/gallery">Gallery</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
      <div className="copyright">
        <div className="row">
          <div className="col-md-6 col-12 ft-left">
            <p>
              <script type="text/javascript">
                var year = new Date();document.write(year.getFullYear());
              </script>{" "}
              Waters Kerala. All rights reserved
            </p>
          </div>
          <div className="col-md-6 col-12 ft-right">
            <p>
              Powered by{" "}
              <a target="_blank" href="https://tissertechnologies.com/">
                Tisser Technologies LLP
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
