import React, { useState, useEffect } from "react";
import InnerNavbar from "../inner-navbar/inner-navbar";
import { Link } from "react-router-dom";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import Footer from "../footer/footer";
import { BASE_URL } from "../../constants/constants";
import { Spinner } from "react-bootstrap";

export default function Stalltest() {
  const [data, setData] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [errordata, seterrordata] = useState("");

  useEffect(() => {
    async function fetchData() {
      setisloading(true);
      try {
        const response = await fetch(BASE_URL + "getstallinfo"); // Replace with the actual API endpoint URL
        const jsonData = await response.json();
        setData(jsonData);
        setisloading(false);
      } catch (error) {
        setisloading(false);
        // seterrordata(error);
        alert(error);
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <InnerNavbar />
      <div className="events-banner container-fluid">
        <div className="banner-bg">
          <h1>Stall Layout</h1>
        </div>
      </div>
      <div className="content">
        {isloading ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            <h4>{errordata}</h4>
            <div className="stall-layout-main container-fluid">
              <div className="custom-container">
                <div className="layout-box">
                  <div className="stage-area">
                    <div className="exit">
                      <h6>Exit</h6>
                    </div>
                    <div className="stage">
                      <h6>Stage</h6>
                    </div>
                  </div>
                  <div className="audience-area">
                    <div className="a-stall-box">
                      <div className="stall-box"></div>
                      <div
                        className={
                          data.length > 0 && data[0].status
                            ? "stall-box blue-box"
                            : "stall-box blue-box booked"
                        }
                      >
                        <p>
                          {data.length > 0 && data[0].company
                            ? data[0].company
                            : "D3"}
                        </p>
                      </div>
                      <div className="stall-box"></div>
                      <div
                        className={
                          data.length > 0 && data[1].status
                            ? "stall-box blue-box"
                            : "stall-box blue-box booked"
                        }
                      >
                        <p>
                          {" "}
                          {data.length > 0 && data[1].company
                            ? data[1].company
                            : "D2"}
                        </p>
                      </div>
                      <div className="stall-box"></div>
                      <div
                        className={
                          data.length > 0 && data[2].status
                            ? "stall-box blue-box"
                            : "stall-box blue-box booked"
                        }
                      >
                        <p>
                          {" "}
                          {data.length > 0 && data[2].company
                            ? data[2].company
                            : "D1"}
                        </p>
                      </div>
                    </div>
                    <div className="audience-box">
                      <h6>Audience Area</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {data.map((item, index) => (
                <div key={index}>
                  {/* Render the data properties here */}
                  <p>{item.id}</p>
                  {/* <p>{item.propertyValue}</p> */}
                  {/* ... */}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
