import React from "react";
import EventImgOne from "../../assets/img/event1.jpg";
import Footer from "../footer/footer";
import InnerNavbar from "../inner-navbar/inner-navbar";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { useEffect } from "react";

export default function MainEventsDetails() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <InnerNavbar />
      <div className="about-banner container-fluid">
        <div className="banner-bg">
          <h1>Water Expo</h1>
        </div>
      </div>
      <div className="events-detail-main container-fluid">
        <div className="custom-container">
          <div className="row">
            <div class="col-md-9 col-12">
              <div className="event-content">
                <h4>Water Expo</h4>
                <p>
                  Waters Kerala Water Expo is an unparalleled gathering of
                  industry leaders, experts, and enthusiasts, aimed at fostering
                  innovation, collaboration, and growth. With a diverse range of
                  activities and an array of prominent speakers, it promises to
                  be an extraordinary event that will leave a lasting impact on
                  all attendees.
                </p>
                <p>
                  Unrivaled Exposure: Gain access to a targeted audience
                  comprising decision-makers and potential clients from your
                  industry and beyond. Showcase your products and services to a
                  captive audience actively seeking new opportunities. Brand
                  Visibility: Maximize your brand's visibility through our
                  comprehensive marketing campaigns, including social media,
                  email newsletters, press releases, and more. Your logo will be
                  prominently displayed on event materials and collaterals.
                  Networking Opportunities: Build valuable connections through
                  networking sessions, interactive workshops, and social events.
                  Forge strategic partnerships and explore collaborations with
                  like-minded professionals. Thought Leadership: Position your
                  company as a thought leader by sharing your insights during
                  panel discussions or conducting workshops. Establish yourself
                  as an authority in your field. On-site Promotions: Set up an
                  eye-catching booth at the event to engage attendees directly
                  and leave a lasting impression. Distribute branded merchandise
                  and marketing materials to reinforce your presence.
                </p>
                <p>
                  Media Coverage: Benefit from media exposure and potential
                  coverage in industry-specific publications, blogs, and news
                  outlets. Increase Sales and Leads: Generate leads, nurture
                  prospects, and convert potential customers into loyal clients
                  during the event. Join us in making Waters Kerala Water Expo
                  an unforgettable success, leaving a lasting impact on
                  attendees and participants alike.
                </p>
                <h6>Event Sponsor</h6>
                <ul>
                  <li>1.6x3 stall complementary</li>
                  <li>
                    Exclusive 30-minute training/presentation session on stage
                  </li>
                  <li>Video ads: 30-second video into 900 slots</li>
                  <li>
                    Every event banner will be displayed with sponsor logo
                  </li>
                  <li>
                    30 Hoardings of events with sponsor logo displayed at
                    premises
                  </li>
                  <li>60 flags displaying with sponsor logo</li>
                  <li>
                    2 months of social media promotions with sponsor logo and
                    name
                  </li>
                  <li>MC event announcement will be with sponsor name</li>
                  <li>
                    Special English, Hindi, Malayalam language translator
                    exclusively for two days
                  </li>
                  <li>Arch entrance promotion logos</li>
                </ul>

                <h6>Co Sponsor</h6>
                <ul>
                  <li>6x3 stall complementary</li>
                  <li>
                    Exclusive 20-minute training/presentation session on stage
                  </li>
                  <li>25-second video ads into 800 slots</li>
                  <li>
                    Every event banner will be displayed with co-sponsor logo
                  </li>
                  <li>
                    30 hoardings of events displayed at premises with co-sponsor
                    logo
                  </li>
                  <li>60 flags displaying with sponsor logo</li>
                  <li>
                    2 months of social media promotions will feature sponsor
                    logo and name
                  </li>
                  <li>
                    MC event announcement will also include co-sponsor name
                  </li>
                  <li>
                    Special English, Hindi, and Malayalam language translator
                    exclusively for two days
                  </li>
                  <li>Arch entrance promotion logos</li>
                </ul>

                <h6>3x3 Stall Green or Red</h6>
                <p>Features</p>
                <ul>
                  <li>Visibility in social media ads</li>
                  <li>20-second video promo into 500 slots</li>
                  <li>30 hoarding in event premises with stall participants</li>
                  <li>Arch entrance display promotion</li>
                  <li>10-minute training or presentation time on stage</li>
                </ul>

                <h6>3x3 Yellow Stall</h6>
                <p>Features</p>
                <ul>
                  <li>Visibility in social media ads</li>
                  <li>15-second video promo into 250 slots</li>
                  <li>30 hoardings in event premises with logo</li>
                  <li>5-minute training or presentation time on stage</li>
                </ul>

                <div className="reg-btn">
                  <a href="https://waterskerala.com/app/expo24/index.php">Register Now</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-12">
              <div className="event-date">
                <h6>Date</h6>
                <h4>27-09-2024</h4>

                <h6>Venue</h6>
                <h4>Ashish Convention Centre, kalamassery, Ernakulam</h4>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="event-img">
                <img src={EventImgOne} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop smooth />
    </div>
  );
}
